import axios from "axios";

const efectivoQR = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  let facturaJson = localStorage.getItem("facturaJson");
  const idPagoElectronico = localStorage.getItem("idPagoElectronico");

  if (!facturaJson) {
    console.error("No se encontró facturaJson en localStorage");
    return;
  }

  facturaJson = JSON.parse(facturaJson);
  facturaJson.cabecera = { ...facturaJson.cabecera, idPagoElectronico }; // Agregar idPagoElectronico a cabecera
  localStorage.setItem("facturaJson", JSON.stringify(facturaJson));

  const userData = { nic: formattedNic, token: token, venta: facturaJson };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Facturacion/efectivoQR`, {
      userData,
    });
    console.log(response);
    return response;
  } catch (error) {
    // Manejo del error
    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango 2xx
      console.error("Error en la respuesta:", error.response.data);
      return { error: error.response.data.exceptionMessage || "Error desconocido del servidor" };
    } else if (error.request) {
      // La solicitud fue realizada pero no hubo respuesta
      console.error("No hubo respuesta del servidor:", error.request);
      return { error: "No hubo respuesta del servidor, intente de nuevo más tarde." };
    } else {
      // Algo pasó al preparar la solicitud que lanzó un error
      console.error("Error al preparar la solicitud:", error.message);
      return { error: "Error al preparar la solicitud: " + error.message };
    }
  }
};

const ventaEfectivoQR = {
  efectivoQR,
};

export default ventaEfectivoQR;
