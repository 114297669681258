import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { handlePagoEvent, showLoadingSpinner } from "../helpers/pagosHelper";
import MenuSection from "../components/MenuSection";
import { useSelector, useDispatch } from "react-redux";

const CuotasPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCuota, setSelectedCuota] = useState("1"); // Por defecto la cuota es "1"
  const nic = localStorage.getItem("formattedNic");
  const dispatch = useDispatch();
  // Obtener productos desde Redux
  const productos = useSelector(state => state.productos.productos);
  const theme = useTheme();
  const totalProductos = +localStorage.getItem("totalProductos");
  let cuotas = [];
  // Fallback: si no hay productos en Redux, cargar desde localStorage
  useEffect(() => {
    const productosFromLocalStorage = localStorage.getItem("productos");
    if (productos.length === 0 && productosFromLocalStorage) {
      dispatch({ type: "CARGAR_PRODUCTOS_DESDE_LOCALSTORAGE", payload: JSON.parse(productosFromLocalStorage) });
    }
  }, [productos, dispatch]);

  useEffect(() => {
    const eventListener = handlePagoEvent(setIsLoading, navigate);
    window.addEventListener("responsePagoConTarjeta", eventListener);

    return () => {
      window.removeEventListener("responsePagoConTarjeta", eventListener);
    };
  }, [navigate]);

  // Si no hay productos cargados, mostrar un mensaje de carga
  if (!productos || productos.length === 0) {
    return <Typography variant="h6">Cargando productos...</Typography>;
  }

  // Función para calcular el total de las cuotas según la opción seleccionada
  const calcularTotalCuotas = (totalProductos, productos, cuotaSeleccionada) => {
    return productos
      .reduce((total, producto) => {
        if (cuotaSeleccionada === "1") {
          return totalProductos;
        } else if (cuotaSeleccionada === "3") {
          return total + (producto.precioLista3 || 0); // Sumar precios de cuota 3
        } else if (cuotaSeleccionada === "6") {
          return total + (producto.precioLista6 || 0); // Sumar precios de cuota 6
        }
        return total;
      }, 0)
      .toFixed(2); // Asegura que el total siempre tenga dos decimales
  };

  // Definir las opciones de cuotas en función de la selección

  if (nic == "https://laposta.caldenoil.com:5000/") {
    cuotas = [
      { cuota: "1", total: calcularTotalCuotas(totalProductos, productos, "1"), numCuotas: 1 },
      { cuota: "3", total: calcularTotalCuotas(totalProductos, productos, "3"), numCuotas: 3 },
      { cuota: "6", total: calcularTotalCuotas(totalProductos, productos, "6"), numCuotas: 6 },
    ].filter(option => option.cuota === "1" || option.total > 0); // Filtrar opciones que tengan total mayor a 0
  } else {
    cuotas = [
      { cuota: "1", total: calcularTotalCuotas(totalProductos, productos, "1"), numCuotas: 1 },
      // { cuota: "3", total: calcularTotalCuotas(totalProductos, productos, "3"), numCuotas: 3 },
      // { cuota: "6", total: calcularTotalCuotas(totalProductos, productos, "6"), numCuotas: 6 },
    ].filter(option => option.cuota === "1" || option.total > 0); // Filtrar opciones que tengan total mayor a 0
  }

  const handleSubmit = () => {
    const selectedOption = cuotas.find(option => option.cuota === selectedCuota);
    const totalConCuotas = selectedOption.total;
    const numeroCuotas = selectedOption.numCuotas;

    try {
      window.MyInterface.startPayment(totalConCuotas, numeroCuotas); // Pasar total y número de cuotas
    } catch (error) {
      console.error("Falló la llamada con dos parámetros, intentando con uno.");
      window.MyInterface.startPayment(totalConCuotas);
    }
  };

  return (
    <>
      <MenuSection />
      {isLoading && showLoadingSpinner()}
      <Box mx={2} mt={window.innerWidth < 700 ? 3 : -8}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Elige la cantidad de cuotas
        </Typography>
        <Box mt={4} display="flex" flexDirection="column" sx={{ accentColor: theme.bandera.colorSecundario }} alignItems="flex-start">
          {cuotas.map(option => (
            <label key={option.cuota} style={{ marginBottom: "20px" }}>
              <input type="radio" value={option.cuota} checked={selectedCuota === option.cuota} onChange={() => setSelectedCuota(option.cuota)} />
              {option.cuota} cuota(s) de ${(option.total / option.numCuotas).toFixed(2)} (Total: ${option.total})
            </label>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: window.innerWidth > 700 ? 2 : 32,
            display: "flex",
            justifyContent: window.innerWidth > 700 ? "center" : "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.bandera.colorSecundario, ":hover": { backgroundColor: theme.bandera.colorSecundario } }}
            onClick={handleSubmit}
          >
            <Typography variant="button" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              CONTINUAR
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CuotasPage;
