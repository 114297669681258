import axios from "axios";
import generarFacturaJson from "../helpers/generarFacturaJson";

const getPuntos = async dni => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const idTalonarioB = +localStorage.getItem("idTalonarioB");

  console.log(puesto);

  const requestData = {
    nic: formattedNic,
    token: token,
    datos: {
      cabecera: {
        TipoComprobante: "Factura",
        LetraComprobante: "",
        IdTalonario: idTalonarioB,
        IdPuesto: puesto.idPuesto,
        NumeroDocumento: dni,
      },
      detalle: [],
      despachosSeleccionados: [],
    },
  };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/TarjetasCredito/ConsultarSaldoShellBox`, requestData);

    return response.data;
  } catch (error) {
    throw error;
  }
};

const acumulaPuntos = async dni => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
  const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
  const prefijo = localStorage.getItem("prefijoFront") || null;
  const clienteString = localStorage.getItem("cliente");
  const cliente = clienteString ? JSON.parse(clienteString) : null;
  const CuponClover = JSON.parse(localStorage.getItem("CuponClover"));
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  let facturaJsonPuntos = generarFacturaJson(CuponClover, puesto, cliente, facturaInfo, totalProductos, prefijo, null);

  facturaJsonPuntos.detalle = facturaJsonPuntos.detalle.map(articulo => ({
    ...articulo,
    IdMangueraSeleccionada: null,
    DespachoManual: false,
  }));

  const userData = {
    nic: formattedNic,
    token: token,
    datos: facturaJsonPuntos,
  };

  try {
    const response = await axios.post(`https://facturador-proxy.azurewebsites.net/api/Facturacion/SugerirAcumularLatamPass`, userData);

    return response;
  } catch (error) {
    throw error;
  }
};

const ConsultarSaldoShellBox = {
  getPuntos,
  acumulaPuntos,
};

export default ConsultarSaldoShellBox;
