import React, { useState, useEffect } from "react";
import { InputLabel, Box, Chip, Button, Card, CardContent, TextField, List, ListItem, Paper, useMediaQuery, Modal, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PaymentIcon from "@mui/icons-material/Payment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import MenuSection from "./MenuSection";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import estadoDespacho from "../api/estadoDespacho";
import percibePercepciones from "../api/percibePerceciones";
import { FiAlertCircle } from "react-icons/fi";
import generarFacturaJson from "../helpers/generarFacturaJson";
import { validateCUITCUIL, formatCuit, handleCuitChange } from "../helpers/useCuit";
import { limpiarDespachos, handleDeleteRow } from "../helpers/useArticulos";
import { generarFacturaInfo } from "../helpers/generarFacturaInfo"; // Importar el nuevo helper
import AirlineSeatReclineNormalOutlinedIcon from "@mui/icons-material/AirlineSeatReclineNormalOutlined";

const FacturacionForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [total, setTotal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [printingModalOpen, setPrintingModalOpen] = useState(false);
  const [isSuspenderButtonDisabled, setIsSuspenderButtonDisabled] = useState(true);
  const [isPagosButtonDisabled, setIsPagosButtonDisabled] = useState(false);
  const [isEmitirFacturaEnabled, setIsEmitirFacturaEnabled] = useState(false);
  const [isSuspenderRemitoEnabled, setIsSuspenderRemitoEnabled] = useState(true);
  const productos = useSelector(state => state.productos.productos);
  const [cuit, setCuit] = useState("");
  const [cuitError, setCuitError] = useState(false);
  const [condicion, setCondicion] = useState("CONTADO");
  const [clientePercepciones, setClientePercepciones] = useState(0);
  const [cliente, setCliente] = useState(null);
  const limiteAfip = localStorage.getItem("limite");
  const theme = useTheme();
  const isScreenWidthLessThan600 = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const clienteStr = localStorage.getItem("cliente");
    if (clienteStr) {
      const clienteData = JSON.parse(clienteStr);
      setCliente(clienteData);
      localStorage.setItem("emiteFacturayRemito", clienteData.emiteFacturayRemito);
    }
  }, []);

  useEffect(() => {
    const tieneProductosConImportePositivo = productos.some(producto => producto.importe > 0);

    if (cliente !== null) {
      if (cliente.condicionVenta.condicionVentaContado === false) {
        // Cliente con cuenta corriente
        setCondicion("CTA. CTE.");
        setIsEmitirFacturaEnabled(tieneProductosConImportePositivo);
        setIsSuspenderButtonDisabled(false);
        setIsPagosButtonDisabled(true);
      } else if (cliente.condicionVenta.condicionVentaContado === true) {
        // Cliente con condición contado
        setCondicion("CONTADO");
        setIsEmitirFacturaEnabled(false);
        setIsSuspenderButtonDisabled(true);
        setIsPagosButtonDisabled(!tieneProductosConImportePositivo);
      }
    } else {
      // Sin cliente seleccionado
      setCondicion("CONTADO");
      setIsEmitirFacturaEnabled(false);
      setIsSuspenderButtonDisabled(true);
      setIsPagosButtonDisabled(!tieneProductosConImportePositivo);
    }
  }, [cliente, productos]);

  useEffect(() => {
    if (cliente && cliente.condicionVenta.condicionVentaContado === false) {
      setIsPagosButtonDisabled(true); // Asegurar que el botón de Pagos esté deshabilitado
    }
  }, [cliente, isPagosButtonDisabled]);

  const handleFacturaElectronica = () => {
    const facturaInfo = generarFacturaInfo(productos, cliente);
    localStorage.setItem("facturaInfo", JSON.stringify(facturaInfo));
    const completoDatosChofer = localStorage.getItem("completoDatosChofer");
    console.log(completoDatosChofer);
    console.log(cliente.exigeDatosVehiculo);
    if (cliente.exigeDatosVehiculo) {
      // Si exigeDatosVehiculo es true, verifica si completoDatosChofer es true
      if (completoDatosChofer === "true") {
        navigate("/firma/");
      } else {
        navigate("/choferespage/");
      }
    } else {
      // Si exigeDatosVehiculo es false, va directo a firmar
      navigate("/firma/");
    }
  };

  const showFormasDePago = () => {
    const facturaInfo = generarFacturaInfo(productos, cliente);
    localStorage.setItem("facturaInfo", JSON.stringify(facturaInfo));
    const completoDatosChofer = localStorage.getItem("completoDatosChofer");
    console.log(facturaInfo.total);

    if (cliente?.exigeDatosVehiculo) {
      // Si exigeDatosVehiculo es true, verifica si completoDatosChofer es true
      if (completoDatosChofer === "true") {
        navigate("/formasdepagopage/");
      } else {
        navigate("/choferespage/");
      }
    } else if (limiteAfip < +facturaInfo.total && localStorage.getItem("cliente") === "") {
      // Si exigeDatosVehiculo es false
      setShowModal(true);
    } else {
      navigate("/formasdepagopage");
    }
  };

  const handleDeleteAll = () => {
    setTotal(0);
    dispatch({ type: "BORRAR_PRODUCTOS" });
    setCondicion("CONTADO");
    setIsEmitirFacturaEnabled(false);
    setIsPagosButtonDisabled(true);
    localStorage.setItem("cliente", "");
    limpiarDespachos(estadoDespacho, JSON.parse(localStorage.getItem("despachosSeleccionados")) || []);
  };

  useEffect(() => {
    const updateFacturaInfo = async () => {
      const facturaInfo = generarFacturaInfo(productos, cliente);
      const total = facturaInfo.total;

      const puesto = JSON.parse(localStorage.getItem("puesto"));
      const emiteFacturayRemito = localStorage.getItem("emiteFacturayRemito");

      const facturaJsonLocal = generarFacturaJson(null, puesto, cliente, facturaInfo, parseFloat(total), null, emiteFacturayRemito);

      if (cliente && localStorage.getItem("percepciones") === "true") {
        try {
          console.log("entro a percepciones?");
          const percepciones = await percibePercepciones.getPercibePercepciones(facturaJsonLocal);

          if (typeof percepciones === "number") {
            setClientePercepciones(percepciones.toFixed(2));
            localStorage.setItem("percibePercepciones", percepciones.toFixed(2));
            const totalConPercepciones = parseFloat(total) + percepciones;
            setTotal(totalConPercepciones.toFixed(2));
            localStorage.setItem("totalProductos", totalConPercepciones.toFixed(2));
          } else {
            console.error("Las percepciones no son un número:", percepciones);
          }
        } catch (error) {
          console.error("Error obteniendo percepciones:", error);
        }
      } else {
        setTotal(total);
        localStorage.setItem("totalProductos", total);
      }
    };

    updateFacturaInfo();
  }, [productos, cliente]);

  useEffect(() => {
    if (parseFloat(total) > 0) {
      setIsPagosButtonDisabled(false);
    } else {
      setIsPagosButtonDisabled(true);
    }
  }, [total]);

  const handleSuspenderCTACTE = () => {
    localStorage.setItem("idcondicionVenta", 1);
    setIsPagosButtonDisabled(false);
    setIsEmitirFacturaEnabled(false);
    setCondicion("CONTADO");
    cliente.condicionVenta.condicionVentaContado = true;
    cliente.condicionVenta.descripcionCondicionVenta = "CONTADO";
    cliente.emiteRemito = false;
    cliente.emiteRemitoCabecera = false;
    const clienteStr = JSON.stringify(cliente);
    localStorage.setItem("cliente", clienteStr);
  };

  const handleSuspenderRemito = () => {
    setIsSuspenderRemitoEnabled(false);
    cliente.emiteFacturayRemito = false;
    cliente.emiteRemito = false;
    cliente.emiteRemitoCabecera = false;
    const clienteStr = JSON.stringify(cliente);
    localStorage.setItem("cliente", clienteStr);
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalAccept = () => {
    if (validateCUITCUIL(cuit.replace(/-/g, ""))) {
      setShowModal(false);
      localStorage.setItem("cuit", cuit);
      navigate("/formasdepagopage");
    } else {
      setCuitError(true);
    }
  };

  return (
    <>
      <MenuSection />
      <Box mt={-1}>
        <Box>
          <Card sx={{ boxShadow: 0 }}>
            <CardContent>
              <form>
                <Box display="flex" sx={{ my: "7px" }}>
                  <InputLabel
                    sx={{
                      marginRight: "6px",
                      display: cliente && (cliente.emiteFacturayRemito || cliente.emiteRemito) ? "none" : "block",
                      width: isScreenWidthLessThan600 ? "70px" : "50px",
                      fontSize: "15px",
                    }}
                  >
                    Cliente
                  </InputLabel>
                  <TextField
                    value={cliente && cliente.razonSocial ? cliente.razonSocial : ""}
                    size="small"
                    disabled
                    sx={{
                      width: isScreenWidthLessThan600 ? 250 : 340,
                      "& .MuiInputBase-root": {
                        height: "25px",
                        padding: 1,
                        backgroundColor: "lightgrey",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "0.800rem",
                      },
                      marginRight: "15px",
                    }}
                    inputProps={{
                      style: { height: "25px", padding: 0 },
                    }}
                  />
                  <IconButton
                    sx={{
                      display: "inline-flex",
                      padding: "0.2rem",
                      height: "auto",
                      backgroundColor: theme.bandera.colorSecundario,
                      borderRadius: "4px",
                      color: "white",
                      margin: 0,
                    }}
                    onClick={() => {
                      navigate("/choferespage");
                    }}
                  >
                    <AirlineSeatReclineNormalOutlinedIcon />
                  </IconButton>
                  <Chip
                    sx={{
                      display: cliente && (cliente.emiteFacturayRemito || cliente.emiteRemito) ? "inline-flex" : "none",
                      padding: "0.2rem",
                      "& .MuiChip-label": {
                        padding: 0,
                        fontSize: "0.75rem",
                      },
                      height: "auto",
                      margin: "0 3px",
                    }}
                    label="Emite Remito"
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{
                    marginTop: 0.5,
                    marginBottom: 0.5,
                  }}
                >
                  <InputLabel
                    sx={{
                      marginRight: "6px",
                      width: isScreenWidthLessThan600 ? "80px" : "75px",
                      fontSize: "15px",
                    }}
                  >
                    Condición
                  </InputLabel>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      value={condicion}
                      size="small"
                      disabled
                      sx={{
                        fontSize: "15px",
                        backgroundColor: "lightgrey",
                        width: 95,
                        ".MuiInputBase-input": {
                          fontSize: "0.800rem",
                        },
                        ".MuiInputBase-root": {
                          height: "25px",
                        },
                        marginBottom: window.innerWidth < 700 ? "60px" : "20px",
                      }}
                    />

                    {condicion !== "CONTADO" && (
                      <>
                        <Box
                          display="flex"
                          flexDirection={window.innerWidth < 700 ? "column" : "row"}
                          alignItems="center"
                          ml={window.innerWidth < 700 ? 1 : 0}
                          mb={window.innerWidth < 700 ? 0 : 2}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: theme.bandera.colorSecundario,
                              ":hover": {
                                backgroundColor: theme.bandera.colorSecundario,
                              },
                              padding: "0.4rem",
                              fontSize: 11,
                              marginTop: window.innerWidth < 700 ? -2.5 : 0,
                              whiteSpace: "nowrap",
                              marginLeft: window.innerWidth < 700 ? 0 : "10px",
                              marginRight: window.innerWidth < 700 ? 0 : "10px",
                            }}
                            onClick={handleSuspenderCTACTE}
                          >
                            Suspender CTA. CTE.
                          </Button>

                          <Button
                            variant="contained"
                            sx={{
                              fontWeight: "bold",
                              backgroundColor: theme.bandera.colorSecundario,
                              ":hover": {
                                backgroundColor: theme.bandera.colorSecundario,
                              },
                              padding: "0.4rem",
                              fontSize: 11,
                              marginTop: window.innerWidth < 700 ? "0.2rem" : 0,
                            }}
                            disabled={!isSuspenderRemitoEnabled}
                            onClick={handleSuspenderRemito}
                          >
                            Suspender Remito
                          </Button>
                        </Box>
                      </>
                    )}
                  </div>
                </Box>

                <Paper
                  sx={{
                    width: isScreenWidthLessThan600 ? 375 : 505,
                    marginLeft: -2,
                    height: isScreenWidthLessThan600 ? 235 : 160,
                    overflow: "auto",
                    boxShadow: 0,
                    marginBottom: 1,
                    marginTop: -2,
                    "&::-webkit-scrollbar": {
                      width: "7px",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 5px grey",
                      borderRadius: "7px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: theme.palette.grey[400],
                      borderRadius: "7px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: theme.palette.grey[500],
                    },
                  }}
                >
                  <List>
                    {productos.map(producto => (
                      <ListItem
                        key={producto.idProductoArray}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                          }}
                        >
                          <div key={producto.id}>
                            <span style={{ textTransform: "uppercase" }}>{producto.descripcionArticulo || producto.descripcion}</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {producto.cantidadArticulo !== undefined && (
                              <div
                                style={{
                                  backgroundColor: "gray",
                                  fontSize: 13,
                                  color: "white",
                                  borderRadius: "50%",
                                  width: "1.7rem",
                                  height: "1.7rem",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "0.5rem",
                                }}
                              >
                                {`x${producto.cantidadArticulo}`}
                              </div>
                            )}
                            <div style={{ marginLeft: "auto" }}>{`$${producto.importe.toFixed(2)}`}</div>
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          sx={{
                            boxShadow: 0,
                            fontWeight: "bold",
                            backgroundColor: "white",
                            fontSize: 15,
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                              border: "none",
                            },
                          }}
                          onClick={() => handleDeleteRow(producto.idProductoArray, productos, dispatch, estadoDespacho)}
                        >
                          <HighlightOffIcon sx={{ color: "grey", fontSize: 45 }} />
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Paper>

                <Box display="flex">
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 1,
                      fontWeight: "bold",
                      fontSize: 9,
                      lineHeight: 1,
                      width: window.innerWidth < 700 ? 100 : 115,
                      padding: 0.4,
                      textAlign: "left",
                      backgroundColor: theme.bandera.colorSecundario,
                      ":hover": {
                        backgroundColor: theme.bandera.colorSecundario,
                      },
                    }}
                    onClick={handleDeleteAll}
                  >
                    <DeleteIcon />
                    BORRAR TODO
                  </Button>

                  <Box display="flex" sx={{ marginLeft: isScreenWidthLessThan600 ? 1 : 7 }}>
                    <InputLabel sx={{ fontWeight: "bold", fontSize: "12px", marginTop: "3px", marginRight: "5px" }}>Perc</InputLabel>
                    <TextField
                      size="small"
                      disabled
                      value={`$${clientePercepciones}`}
                      sx={{
                        backgroundColor: "lightgrey",
                        width: isScreenWidthLessThan600 ? 90 : 105,
                        ".MuiInputBase-input": {
                          fontSize: "0.750rem",
                        },
                        ".MuiInputBase-root": {
                          overflow: "hidden",
                          height: "30px",
                        },
                      }}
                    />
                  </Box>
                  <Box display="flex" sx={{ marginLeft: isScreenWidthLessThan600 ? 1 : 7 }}>
                    <InputLabel sx={{ fontWeight: "bold", fontSize: "12px", marginTop: "3px", marginRight: "5px" }}>Total</InputLabel>
                    <TextField
                      size="small"
                      disabled
                      value={`$${total}`}
                      sx={{
                        backgroundColor: "lightgrey",
                        width: isScreenWidthLessThan600 ? 90 : 105,
                        ".MuiInputBase-input": {
                          fontSize: "0.750rem",
                        },
                        ".MuiInputBase-root": {
                          overflow: "hidden",
                          height: "30px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>

        <Box display="flex" justifyContent={"center"} mt={-2}>
          <Box mr={isScreenWidthLessThan600 ? 1 : 2}>
            <Button
              variant="contained"
              onClick={handleFacturaElectronica}
              sx={{
                borderRadius: 2,
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
                fontSize: 14,
                width: isScreenWidthLessThan600 ? 115 : 115,
                height: isScreenWidthLessThan600 ? 100 : 100,
                flexDirection: "column",
              }}
              disabled={!isEmitirFacturaEnabled}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <PointOfSaleIcon sx={{ fontSize: isScreenWidthLessThan600 ? 50 : 65 }} />
              </Box>
              CTA. CTE.
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              onClick={showFormasDePago}
              sx={{
                borderRadius: 2,
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
                fontSize: 14,
                width: isScreenWidthLessThan600 ? 115 : 115,
                height: isScreenWidthLessThan600 ? 100 : 100,
                flexDirection: "column",
              }}
              disabled={isPagosButtonDisabled}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <PaymentIcon sx={{ fontSize: isScreenWidthLessThan600 ? 50 : 65 }} />
              </Box>
              PAGOS
            </Button>
          </Box>
        </Box>
      </Box>

      <Modal open={showModal} onClose={handleModalCancel}>
        <Box
          sx={{
            "& .MuiDialog-paper": {
              padding: theme.spacing(1),
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.shadows[5],
              borderRadius: theme.shape.borderRadius,
              display: "flex",
              flexDirection: "column",
            },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: theme.spacing(2),
            }}
          >
            <FiAlertCircle size={48} color={theme.palette.info.main} />
          </Box>
          <Typography sx={{ mt: 2, fontSize: 18, fontWeight: 100 }}>Se debe identificar al consumidor final para este importe.</Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 100 }}>Importe máximo: ${limiteAfip}</Typography>
          <Typography sx={{ mt: 2, fontSize: 18, fontWeight: 100 }}>Ingrese un CUIT válido:</Typography>
          <TextField
            label="CUIT"
            value={cuit}
            variant="outlined"
            fullWidth
            sx={{ mb: 4, mt: 2 }}
            onChange={event => handleCuitChange(event, setCuit, setCuitError)}
            error={cuitError}
            helperText={cuitError ? "CUIT debe tener 11 dígitos y ser válido" : ""}
            InputLabelProps={{
              style: { fontSize: "0.85rem", color: "gray" },
            }}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleModalCancel} color="primary" variant="contained" sx={{ mr: 1 }}>
              <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 500 }}>
                Cancelar
              </Typography>
            </Button>
            <Button onClick={handleModalAccept} color="primary" variant="contained">
              <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 500 }}>
                Aceptar
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FacturacionForm;
