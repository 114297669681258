import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Box,
  TextField,
  ListItemText,
  List,
  IconButton,
  ListItem,
  CircularProgress,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
  DialogTitle,
} from "@mui/material";
import debounce from "lodash.debounce";

import SearchIcon from "@mui/icons-material/Search";
import Clientes from "../api/clientes";
import { Link } from "react-router-dom";
import LZString from "lz-string";
import MenuSection from "../components/MenuSection";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Percepciones from "../api/percepciones";

const ClientesSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [modalWidth, setModalWidth] = useState("500px");
  const [isLoading, setIsLoading] = useState(false);
  const [clientesArray, setClientesArray] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showAllResults] = useState(false); // Estado para mostrar todos los resultados
  const navigate = useNavigate();
  const theme = useTheme();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  localStorage.removeItem("idcondicionVenta");
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const clientes = await Clientes.getClientes("");
        console.log(clientes);
        const parsedClientes = Object.values(clientes.data.clientes).filter(cliente => cliente.activo);
        setClientesArray(parsedClientes); // Utiliza directamente los datos del API
        setSearchResults(parsedClientes); // Muestra los resultados iniciales
        setShowButtons(true);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
      if (window.innerWidth < 700) {
        setModalWidth("360px");
      } else {
        setModalWidth("900px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    const esPlaya = localStorage.getItem("solicitarAforadores");
    if (esPlaya == "true") {
      navigate("/dashboard");
    } else {
      navigate("/dashboardmini");
    }
  };

  const handleCancel = () => {
    localStorage.setItem("cliente", "");

    const esPlaya = localStorage.getItem("solicitarAforadores");
    if (esPlaya == "true") {
      navigate("/dashboard");
    } else {
      navigate("/dashboardmini");
    }
  };

  const handleSearch = useCallback(async () => {
    if (searchTerm.trim() === "") {
      // Si el término de búsqueda está vacío, no hacer nada
      return;
    }

    setIsLoading(true);
    try {
      const clientes = await Clientes.getClientes(searchTerm); // Aquí pasas el término de búsqueda
      console.log(clientes);
      const parsedClientes = Object.values(clientes.data.clientes).filter(cliente => cliente.activo);
      setClientesArray(parsedClientes);
      setSearchResults(parsedClientes.slice(0, showAllResults ? parsedClientes.length : 50)); // Muestra los primeros 50 resultados
      setShowButtons(true);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, showAllResults]);

  const debouncedSearch = useCallback(
    debounce(() => {
      handleSearch();
    }, 1000),
    [searchTerm],
  );

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleRowClick = async cliente => {
    console.log("Cliente:", cliente); // Imprime el cliente completo
    console.log("Identificador:", cliente.identificador);
    const clienteStr = JSON.stringify(cliente); // Convierte el objeto a una cadena JSON
    localStorage.setItem("cliente", clienteStr);
    console.log("Cliente guardado en localStorage:", clienteStr);

    const numeroDocumento = cliente.numeroDocumento.replace(/-/g, "");
    console.log(numeroDocumento);
    setIsLoading(true);

    try {
      const percepciones = await Percepciones.getPercepciones(numeroDocumento);
      console.log(numeroDocumento);
      console.log(percepciones);
      localStorage.setItem("percepciones", percepciones);
      if (percepciones == true) {
        handleOpenConfirmDialog();
      } else {
        const esPlaya = localStorage.getItem("solicitarAforadores");
        if (esPlaya == "true") {
          navigate("/dashboard");
        } else {
          navigate("/dashboardmini");
        }
      }
    } catch (error) {
      console.error("Error al obtener percepciones:", error);
    }
  };

  const handleClose = () => {
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleCancelar = () => {
    setSearchTerm("");
    setSearchResults([]);
    localStorage.setItem("cliente", "");
    window.history.back();
  };

  useEffect(() => {
    // Asegura que se muestren los resultados basados en lo que hay en localStorage inmediatamente después de cargar.
    // No necesitas llamar a handleSearch aquí porque ya estás estableciendo los resultados basados en localStorage arriba.
    if (clientesArray.length > 0) {
      setSearchResults(clientesArray.slice(0, showAllResults ? clientesArray.length : 50));
    }
  }, [clientesArray, showAllResults]);
  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "45%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "80vh" : "90%",
        }}
      >
        <TextField
          label="Razón social del cliente"
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          onKeyDown={event => {
            if (event.key === "Enter") {
              handleSearch();
            }
          }}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearch}>
                <SearchIcon sx={{ height: "2rem", width: "2rem" }} />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            // Add this to style the input label
            style: { fontSize: "0.85rem", color: "gray" },
          }}
        />

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "8rem",
              height: window.innerWidth < 700 ? 423 : 290,
            }}
          >
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
          </div>
        ) : (
          <>
            <Paper
              sx={{
                height: window.innerWidth < 700 ? 409 : 275,
                overflow: "auto",
                boxShadow: 0,
                marginBottom: 2,
              }}
            >
              {searchResults.map(
                (
                  cliente, // Cambia 'articulosArray' por 'searchResults' aquí
                ) => (
                  <List key={cliente.idCliente}>
                    <ListItem
                      key={cliente.idCliente}
                      sx={{
                        borderBottom: "1px solid #ccc",
                        display: "flex",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      component={Link}
                      onClick={() => {
                        handleRowClick(cliente);
                      }}
                    >
                      <ListItemText
                        primary={cliente.razonSocial}
                        secondary={cliente.identificador ? cliente.identificador : ""} // Solo muestra el identificador si no es null
                        sx={{
                          display: "flex",
                          flexDirection: "column", // Asegura que primary y secondary se alineen verticalmente
                          justifyContent: "center",
                          "& .MuiListItemText-primary": {
                            marginRight: 1.5, // Ajusta el espacio entre primary y secondary si es necesario
                          },
                          "& .MuiListItemText-secondary": {
                            color: "gray", // Opcional: Cambia el color del texto secundario si lo deseas
                          },
                        }}
                      />
                    </ListItem>
                  </List>
                ),
              )}
            </Paper>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: window.innerWidth < 700 ? "center" : "flex-end",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  paddingRight: window.innerWidth < 700 ? 2.5 : 1.5,
                  paddingLeft: window.innerWidth < 700 ? 2.5 : 1.5,
                  fontWeight: "bold",
                  fontSize: 17,
                  whiteSpace: "nowrap", // Aquí se aplica la propiedad nowrap
                  marginRight: window.innerWidth < 700 ? 5 : 0,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                component={Link}
                to="/altacliente/"
              >
                ALTA CLIENTE
              </Button>
              <Button
                variant="contained"
                sx={{
                  marginLeft: "1rem",
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                onClick={handleCancelar}
              >
                CANCELAR
              </Button>
            </Box>
            <Dialog
              open={openConfirmDialog}
              onClose={handleCloseConfirmDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiDialog-paper": {
                  backgroundColor: theme.palette.background.default,
                  boxShadow: theme.shadows[5],
                  borderRadius: theme.shape.borderRadius,
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Cliente con percepciones"}</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ fontSize: "1rem" }}>El cliente percibe percepciones ¿desea continuar?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Cancelar
                  </Typography>
                </Button>

                <Button onClick={handleConfirm} color="primary" autoFocus>
                  <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Aceptar
                  </Typography>
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </div>
      </Box>
    </>
  );
};

export default ClientesSearchPage;
