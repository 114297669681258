import React, { useState } from "react";
import { Box, Button, Alert, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import MenuSection from "../components/MenuSection";

const ObtenerDNIPuntos = () => {
  const [showDNIForm, setShowDNIForm] = useState(false);
  const [dni, setDni] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const handleDNIFormSubmit = async () => {
    const isValidDNI = /^\d{8}$/.test(dni);
    if (!isValidDNI) {
      setAlertMessage("Por favor, ingrese un DNI válido.");
      return;
    }

    localStorage.setItem("numeroDocumentoSHELL", dni);
    localStorage.setItem("cargaPuntoShellBox", true);
    const searchParams = new URLSearchParams(location.search);
    const medioDePago = searchParams.get("medioDePago");

    navigate(`/formasdepagopage?medioDePago=${medioDePago}`);
  };

  const handleNoClick = () => {
    const searchParams = new URLSearchParams(location.search);
    const medioDePago = searchParams.get("medioDePago");
    navigate(`/formasdepagopage?medioDePago=${medioDePago}`);
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: "80%",
          maxWidth: "600px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          boxShadow: 3,
        }}
      >
        {!showDNIForm ? (
          <>
            <Typography sx={{ mb: 4, textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}>¿Desea sumar puntos ShellBox?</Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={() => setShowDNIForm(true)}
                variant="contained"
                sx={{
                  backgroundColor: theme.bandera?.colorSecundario,
                  color: "white",
                  fontWeight: "bold",
                  width: "45%",
                  height: "80px", // Aumenta el alto del botón
                  borderRadius: "8px", // Puedes cambiar el borde para hacerlo más cuadrado o más redondeado
                  fontSize: "18px", // Aumenta el tamaño de la fuente
                  ":hover": {
                    backgroundColor: theme.bandera?.colorSecundario,
                  },
                }}
              >
                Sí
              </Button>

              <Button
                onClick={handleNoClick}
                variant="outlined"
                sx={{
                  color: theme.bandera?.colorSecundario,
                  fontWeight: "bold",
                  width: "45%",
                  height: "80px", // Aumenta el alto del botón
                  borderRadius: "8px", // Ajusta el borde
                  fontSize: "18px", // Aumenta el tamaño de la fuente
                  borderWidth: "2px", // Grosor del borde
                }}
              >
                No
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mb: 4, textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}>Ingrese el DNI</Typography>

            <TextField
              label="DNI"
              value={dni}
              variant="outlined"
              fullWidth
              type="number"
              sx={{ mb: 4 }}
              onChange={e => setDni(e.target.value)}
              InputLabelProps={{
                style: { fontSize: "0.85rem", color: "gray" },
              }}
            />

            <Button
              onClick={handleDNIFormSubmit}
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: theme.bandera?.colorSecundario,
                color: "white",
                fontWeight: "bold",
                ":hover": {
                  backgroundColor: theme.bandera?.colorSecundario,
                },
              }}
            >
              Aceptar
            </Button>

            {alertMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {alertMessage}
              </Alert>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ObtenerDNIPuntos;
