import React, { useState, useEffect, useRef } from "react";
import { Alert, Button, Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import HomeButton from "../components/HomeButton";
import MenuSection from "../components/MenuSection";
import configuracionDespachos from "../api/configuracionDespachos";
import { CgDanger } from "react-icons/cg";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import estadoDespacho from "../api/estadoDespacho";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useDispatch } from "react-redux"; // Importa useDispatch

const Home = () => {
  localStorage.setItem("cliente", "");
  localStorage.setItem("emiteFacturayRemito", "");
  localStorage.removeItem("facturaJson");
  localStorage.setItem("empleadoVendedor", "");
  localStorage.removeItem("CuponClover");
  localStorage.setItem("facturaFinal", "");
  localStorage.setItem("processCompleted", "false");
  localStorage.setItem("processCompletedFacturaA", "false");
  localStorage.setItem("processCompletedFacturaB", "false");
  localStorage.setItem("processCompletedRemito", "false");
  localStorage.setItem("percibePercepciones", 0);
  localStorage.setItem("totalProductos", 0);
  localStorage.removeItem("idTarjeta");
  localStorage.setItem("reintentos", 0);
  localStorage.removeItem("errorMensajeCierreTurno");
  localStorage.removeItem("condicionVenta");
  localStorage.removeItem("pagoConQR");
  localStorage.removeItem("chofer");
  localStorage.removeItem("cargaPuntoShellBox");
  localStorage.removeItem("numeroDocumentoSHELL");
  localStorage.removeItem("vengoDeTransferencia");
  localStorage.removeItem("idcondicionVenta");
  localStorage.setItem("despachoManual", false);
  localStorage.setItem("idMangueraSeleccionada", "");
  localStorage.setItem("completoDatosChofer", false);
  localStorage.removeItem("idEmpleadoVendedor");
  localStorage.removeItem("idEmpleadoResponsable");
  localStorage.setItem("reintentos", 0);
  // localStorage.setItem("cuit", null);

  const [showImprimirModal, setShowImprimirModal] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [contentVisible, setContentVisible] = useState(true);
  const [position, setPosition] = useState({ top: -70, left: 10 });
  const navigate = useNavigate();
  const location = useLocation();
  const puesto = localStorage.getItem("puesto");
  const alertRef = useRef(null); // Referencia al div del Alert para manejar clics fuera
  const puestoItem = localStorage.getItem("puesto");
  const puestoNombre = puestoItem ? JSON.parse(puestoItem).nombre : "No definido";
  const solicitarAforadores = localStorage.getItem("solicitarAforadores");
  const nic = localStorage.getItem("formattedNic");
  const dispatch = useDispatch(); // Obtiene la función dispatch
  const storedValue = localStorage.getItem("despachoPagado");
  const despachoPagado = storedValue && storedValue !== "undefined" && storedValue !== "" ? JSON.parse(storedValue) : false;
  console.log(despachoPagado);

  const handleClickOutside = event => {
    if (alertRef.current && !alertRef.current.contains(event.target)) {
      handleClose(); // Cierra el alert si el clic es fuera del elemento
    }
  };
  dispatch({ type: "REINICIAR_PRODUCTOS" });

  useEffect(() => {
    const updatePosition = () => {
      if (window.innerWidth > 600) {
        setPosition({ top: "auto", left: "auto", bottom: 10, right: 20 });
      } else {
        setPosition({ top: -70, left: 10 });
      }
    };

    updatePosition(); // Llama a la función una vez al montarse el componente
    window.addEventListener("resize", updatePosition); // Añade el evento

    return () => {
      window.removeEventListener("resize", updatePosition); // Limpia el evento al desmontarse
    };
  }, []);

  useEffect(() => {
    // Función para limpiar los despachos al salir de la página o bajo cierta condición
    limpiarDespachos();
    // Ejecutar limpiarDespachos bajo ciertas condiciones
    window.addEventListener("beforeunload", limpiarDespachos); // Ejemplo de evento

    return () => {
      window.removeEventListener("beforeunload", limpiarDespachos);
      limpiarDespachos(); // También se podría llamar aquí para asegurar la limpieza
    };
  }, []); // Dependencias
  useEffect(() => {
    // Agrega escuchadores cuando el alert está abierto
    if (alertOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Limpieza del evento
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [alertOpen]);

  const limpiarDespachos = async () => {
    // console.log("hola?");
    const despachosArray = JSON.parse(localStorage.getItem("despachosSeleccionados")) || [];
    console.log(despachoPagado);
    if (despachoPagado == false) {
      for (let despacho of despachosArray) {
        // console.log(despacho);
        try {
          await estadoDespacho.actualizarDespacho(despacho, 0, 0);
        } catch (error) {
          console.error("Error al actualizar el despacho:", error);
        }
      }
      localStorage.removeItem("despachosSeleccionados"); // Limpia el array después de actualizar
    }
    localStorage.setItem("despachoPagado", false);
  };

  useEffect(() => {
    if (location.pathname === "/home") {
      // console.log("entro aca?");
      window.history.pushState(null, "", "/");
    }
  }, [location]);

  useEffect(() => {
    const limpiarArticulos =
      localStorage.getItem("responsePagoConTarjetaJava") === "true" ||
      localStorage.getItem("responsePagoEfectivoJava") === "true" ||
      localStorage.getItem("pagoConCtaCte") === "true";

    if (limpiarArticulos) {
      setShowImprimirModal(true);

      // Reinicia el valor de la variable según el tipo de pago
      if (localStorage.getItem("responsePagoConTarjetaJava") === "true") {
        localStorage.setItem("responsePagoConTarjetaJava", "false");
      } else if (localStorage.getItem("responsePagoEfectivoJava") === "true") {
        localStorage.setItem("responsePagoEfectivoJava", "false");
      } else if (localStorage.getItem("pagoConCtaCte") === "true") {
        localStorage.setItem("pagoConCtaCte", "false");
      }
    } else {
      setShowImprimirModal(false);
    }

    // Guardo el tiempo de Despacho
    const cargarConfiguracionDespacho = async () => {
      try {
        const tiempoDespachos = await configuracionDespachos.getConfiguracionDespachos();
        console.log(tiempoDespachos);
        localStorage.setItem("cantidadSegundosDespacho", JSON.stringify(tiempoDespachos.despachos_TiempoAMostrar));
        localStorage.setItem("tiempoDeRefresco", JSON.stringify(tiempoDespachos.despachos_TiempoDeRefresco));
        localStorage.setItem("remitosFiscalesValorizado", JSON.stringify(tiempoDespachos.remitosFiscalesValorizado));

        const cantidad = tiempoDespachos.despachos_CantidadAMostrar === 0 ? 25 : tiempoDespachos.despachos_CantidadAMostrar;
        localStorage.setItem("cantidadAMostrar", JSON.stringify(cantidad));
      } catch (error) {
        console.error("Error al obtener la configuración de despachos:", error);
      }
    };

    cargarConfiguracionDespacho();
  }, []);

  const handleAlertOpen = message => {
    setAlertMessage(message);
    setAlertOpen(true);
    setContentVisible(false);
  };

  const handleClose = () => {
    setAlertOpen(false);
    setContentVisible(true);
  };

  const handleBuscar = route => () => {
    const puestoIdCaja = JSON.parse(localStorage.getItem("puesto"));
    const faltaPuesto = localStorage.getItem("puesto") === null || localStorage.getItem("puesto") === undefined;
    const faltaPrefijo = localStorage.getItem("prefijoFront") === null || localStorage.getItem("prefijoFront") === undefined;
    const faltaIdCaja = puestoIdCaja?.caja?.idCaja === null || puestoIdCaja?.caja?.idCaja === undefined || puestoIdCaja?.caja?.idCaja === "";

    if (!faltaPuesto && !faltaPrefijo && !faltaIdCaja) {
      navigate(route);
    } else {
      let mensajeAlerta;

      if (faltaPuesto && faltaPrefijo && faltaIdCaja) {
        mensajeAlerta = (
          <>
            Sin <strong>puesto</strong> designado, el ADMIN debe elegir un <strong>puesto</strong>.
          </>
        );
      } else if (faltaPuesto) {
        mensajeAlerta = (
          <>
            Sin <strong>puesto</strong> designado, el ADMIN debe elegir un <strong>puesto</strong>.
          </>
        );
      } else if (faltaIdCaja) {
        mensajeAlerta = (
          <>
            Sin <strong>caja</strong> asignada, el ADMIN debe ingresar un <strong>puesto</strong>.
          </>
        );
      } else {
        mensajeAlerta = (
          <>
            Sin <strong>prefijo</strong> designado, el ADMIN debe ingresar un <strong>prefijo</strong>.
          </>
        );
      }

      handleAlertOpen(
        <>
          <Box fontSize="20px" width="220px">
            <Box component="span" sx={{ marginLeft: 0.5, marginRight: 0.5 }}>
              {mensajeAlerta}
            </Box>
          </Box>
        </>,
      );
    }
  };

  return (
    <>
      <MenuSection />
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Alinea los elementos al inicio (izquierda)
          width: window.innerWidth < 700 ? "100%" : "80%",
          marginTop: window.innerWidth < 700 ? "60px" : "30px",
        }}
      >
        <Typography
          style={{
            position: "absolute", // Posicionamiento absoluto
            ...position,
            top: window.innerWidth < 700 ? -40 : 330,

            fontSize: "11px",
          }}
        >
          <strong>Puesto:</strong> {puestoNombre}
          <br></br>
          <strong>Prefijo:</strong> {localStorage.getItem("prefijoFront")}
          <br></br>
          <strong>PosicionQR:</strong> {localStorage.getItem("idPosicionQR")}
        </Typography>
        <Box sx={{ marginTop: 2, width: "100%" }}>
          <div style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: window.innerWidth < 700 ? "column" : "row" }}>
            {solicitarAforadores == "true" && (
              <HomeButton
                icon={<LocalGasStationIcon sx={{ height: window.innerWidth < 700 ? 75 : 85, width: 100 }} />}
                label="BUSCAR DESPACHO"
                onClick={handleBuscar("/DespachosSearchPage/")}
              />
            )}
            <HomeButton
              icon={<SearchIcon sx={{ height: window.innerWidth < 700 ? 75 : 85, width: 100 }} />}
              label="BUSCAR ARTÍCULO"
              onClick={handleBuscar("/ArticulosSearchPage/")}
            />
          </div>
          <div style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: window.innerWidth < 700 ? "column" : "row", mt: "-60px" }}>
            <HomeButton
              icon={<PersonIcon sx={{ height: window.innerWidth < 700 ? 75 : 85, width: 100 }} />}
              label="BUSCAR CLIENTE"
              onClick={handleBuscar("/ClientesSearchPage/")}
            />

            <HomeButton
              icon={<AccountBalanceWalletIcon sx={{ height: window.innerWidth < 700 ? 75 : 85, width: 100 }} />}
              label="TRANSFERENCIAS"
              onClick={handleBuscar("/transferenciapage/")}
            />
          </div>
        </Box>
        <HomeButton
          icon={<NoEncryptionGmailerrorredIcon sx={{ height: window.innerWidth < 700 ? 75 : 85, width: 100 }} />}
          label="CERRAR TURNO"
          onClick={handleBuscar("/CierreTurnoPage/")}
        />
      </div>
      {alertOpen && (
        <div ref={alertRef} style={{ position: "fixed", top: "150px", left: "50%", transform: "translateX(-50%)", zIndex: 1000 }}>
          <Alert
            severity="info"
            icon={<CgDanger style={{ fontSize: "4rem" }} />}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& .MuiAlert-message": {
                fontSize: "1.25rem",
                textAlign: "center",
              },
              position: "relative",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  fontSize: "25px",
                }}
              >
                <HighlightOffIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertMessage}
          </Alert>
        </div>
      )}
    </>
  );
};

export default Home;
