export default function generarFacturaJson(CuponClover, puesto, cliente, facturaInfo, totalProductos, prefijo, emiteFacturayRemito) {
  // Lógica para obtener los talonarios
  let idTalonarioEncontradoA = null,
    puntoVentaEncontradoA = null;
  let idTalonarioEncontradoB = null,
    puntoVentaEncontradoB = null;
  let idTalonarioEncontradoREM = null,
    puntoVentaEncontradoREM = null;

  if (puesto && puesto.talonarios) {
    puesto.talonarios.forEach(talonario => {
      talonario.talonarioDocumentos.forEach(documento => {
        switch (documento.idTipoMovimiento) {
          case "REM":
            idTalonarioEncontradoREM = talonario.idTalonario;
            puntoVentaEncontradoREM = talonario.puntoVenta;
            localStorage.setItem("cantidadCopiasREM", talonario.copias);
            localStorage.setItem("idTalonarioREM", idTalonarioEncontradoREM);
            break;
          case "FAB":
            idTalonarioEncontradoB = talonario.idTalonario;
            puntoVentaEncontradoB = talonario.puntoVenta;
            localStorage.setItem("cantidadCopiasFAB", talonario.copias);
            localStorage.setItem("idTalonarioB", idTalonarioEncontradoB);
            break;
          case "FAA":
            idTalonarioEncontradoA = talonario.idTalonario;
            puntoVentaEncontradoA = talonario.puntoVenta;
            localStorage.setItem("cantidadCopiasFAA", talonario.copias);
            localStorage.setItem("idTalonarioA", idTalonarioEncontradoA);
            break;
          default:
            break;
        }
      });
    });
  } else {
    console.error("No se encontró el objeto 'puesto' o la propiedad 'talonarios' dentro de él.");
  }

  // Obtener los datos del chofer desde localStorage
  const chofer = localStorage.getItem("chofer");
  let choferData = null;
  if (chofer) {
    choferData = JSON.parse(chofer);
  }

  const cargaPuntoShellBox = localStorage.getItem("cargaPuntoShellBox") ? JSON.parse(localStorage.getItem("cargaPuntoShellBox")) : false;
  const numeroDocumentoSHELL = localStorage.getItem("numeroDocumentoSHELL") ? localStorage.getItem("numeroDocumentoSHELL") : null;

  // Configuración de datos de pago y cliente
  let amount = 0;
  if (CuponClover && CuponClover.payment) {
    amount = CuponClover.payment.amount / 100;
    CuponClover.payment.amount = amount;
  }

  const limiteAfip = localStorage.getItem("limiteAfip");
  const cuit = localStorage.getItem("cuit");
  const reintentos = parseInt(localStorage.getItem("reintentos") || "0", 10);
  const IdEmpleadoVendedor = localStorage.getItem("idEmpleadoVendedor") || null;
  let NumeroDocumento = null;
  if (limiteAfip < totalProductos) {
    NumeroDocumento = cuit;
  }

  // Datos iniciales de cabecera
  let RazonSocial = "Consumidor Final";
  let LetraComprobante = "B";
  let TipoComprobante = "Factura";
  let Domicilio = "-";
  let Localidad = "-";
  let IdCategoriaIVA = 2;
  let CodigoPostal = 0;
  let IdCliente = null;
  let IdLocalidad = "";
  let IdTalonario = idTalonarioEncontradoB;
  let PuntoVenta = puntoVentaEncontradoB;
  let idPuesto = puesto.idPuesto;
  let idTarjeta = localStorage.getItem("idTarjeta") || "";
  let TipoPago = "CONTADO";
  let OtrasPercepciones = 0;
  let emiteRemito = "";
  let IdTipoDocumento = null;

  if (cliente) {
    IdCategoriaIVA = cliente.categoriaIVA.codigoAFIPCategoriaIVA;
    RazonSocial = cliente.razonSocial;
    IdTipoDocumento = cliente.IdTipoDocumento;
    NumeroDocumento = cliente.numeroDocumento;
    Domicilio = cliente.calle;
    Localidad = cliente.localidad.nombreLocalidad;
    CodigoPostal = cliente.localidad.codigoPostal;
    TipoPago = cliente.condicionVenta.descripcionCondicionVenta;
    IdCliente = cliente.idCliente;
    IdLocalidad = cliente.localidad.idLocalidad;
    emiteRemito = cliente.emiteRemito;

    console.log(cliente.categoriaIVA.abreviaturaCategoriaIVA);
    if (
      cliente.categoriaIVA.abreviaturaCategoriaIVA === "RI" ||
      cliente.categoriaIVA.abreviaturaCategoriaIVA === "MO" ||
      cliente.categoriaIVA.abreviaturaCategoriaIVA === "CF"
    ) {
      if (cliente.condicionVenta.condicionVentaContado === false && cliente.emiteRemito) {
        LetraComprobante = "-";
        TipoComprobante = "Remito";
        IdTalonario = idTalonarioEncontradoREM;
        PuntoVenta = puntoVentaEncontradoREM;
      } else {
        LetraComprobante = "A";
        TipoComprobante = "Factura";
        IdTalonario = idTalonarioEncontradoA;
        PuntoVenta = puntoVentaEncontradoA;
      }
    } else if (cliente.categoriaIVA.abreviaturaCategoriaIVA === "EX") {
      if (cliente.condicionVenta.condicionVentaContado === false && cliente.emiteRemito) {
        LetraComprobante = "-";
        TipoComprobante = "Remito";
        IdTalonario = idTalonarioEncontradoREM;
        PuntoVenta = puntoVentaEncontradoREM;
      } else {
        LetraComprobante = "B";
        TipoComprobante = "Factura";
        IdTalonario = idTalonarioEncontradoB;
        PuntoVenta = puntoVentaEncontradoB;
      }
    } else {
      LetraComprobante = "B";
      TipoComprobante = "Factura";
      IdTalonario = idTalonarioEncontradoB;
      PuntoVenta = puntoVentaEncontradoB;
    }
  }

  const currentDate = new Date();
  const modifiedDate = new Date(currentDate);
  modifiedDate.setHours(currentDate.getHours() - 3);
  const formattedModifiedDate = modifiedDate.toISOString();
  const idcondicionVenta = +localStorage.getItem("idcondicionVenta");
  const idTalonarioREM = +localStorage.getItem("idTalonarioREM");

  let emiteRemitoCabecera = false;
  if (cliente) {
    emiteRemitoCabecera = cliente.emiteRemitoCabecera ?? (cliente.emiteRemito || cliente.emiteFacturayRemito) ? true : false;
  }

  const precioCampo = `precioLista${cliente?.numeroListaPrecios || "Publico"}`;

  const facturaJson = {
    cabecera: {
      Reintentos: reintentos,
      TipoComprobante: TipoComprobante,
      LetraComprobante: LetraComprobante,
      IdTalonario: IdTalonario,
      PuntoVenta: PuntoVenta,
      Numero: 0,
      IdPuesto: idPuesto,
      Fecha: formattedModifiedDate,
      RazonSocial: RazonSocial,
      IdTipoDocumento: IdTipoDocumento,
      IdCategoriaIVA: IdCategoriaIVA,
      NumeroDocumento: NumeroDocumento,
      NumeroDocumentoShell: numeroDocumentoSHELL,
      IdClienteSeleccionado: IdCliente,
      IdEmpleadoVendedor: IdEmpleadoVendedor,
      Domicilio: Domicilio,
      Localidad: Localidad,
      CodigoPostal: CodigoPostal,
      IdLocalidad: IdLocalidad,
      Patente: choferData?.patente || null,
      NumeroVehiculo: choferData?.numeroVehiculo || null,
      Kilometros: choferData?.kilometraje ? parseInt(choferData.kilometraje, 10) : null,
      NombreChofer: choferData?.nombreChofer || null,
      IdChofer: choferData?.idChofer ? parseInt(choferData.idChofer, 10) : null,
      Moneda: "ARS",
      TipoPago: TipoPago,
      IdCondicionVenta: idcondicionVenta || null,
      IdTarjeta: idTarjeta,
      IdCaja: puesto.caja?.idCaja ?? null,
      Prefijo: prefijo,
      Total: totalProductos + OtrasPercepciones,
      emiteRemito: emiteRemitoCabecera,
      idTalonarioREM: idTalonarioREM,
    },
    detalle:
      facturaInfo.articulos?.map(articulo => ({
        Cantidad: articulo.cantidadArticulo || articulo.cantidad,
        DescripcionArticulo: articulo.descripcion || articulo.descripcionArticulo,
        Precio: articulo[precioCampo] !== undefined ? articulo[precioCampo] : articulo.precioPublico,
        IdArticulo: articulo.idArticulo ?? 0,
        IdMangueraSeleccionada: localStorage.getItem("idMangueraSeleccionada") ? +localStorage.getItem("idMangueraSeleccionada") : null,
        DespachoManual: localStorage.getItem("despachoManual") ? JSON.parse(localStorage.getItem("despachoManual")) : false,
      })) || [],
    valores: {
      Caja: "-",
      Efectivo: 0,
      ChequesPropios: 0,
      ChequesTerceros: 0,
      Tarjetas: CuponClover ? CuponClover.payment.amount : 0,
      Transferencias: 0,
      DebitoAutomatico: 0,
      Cupones: CuponClover ? [{ CuponClover: CuponClover }] : [],
    },
    despachosSeleccionados: facturaInfo.despachos?.map(despacho => despacho.idDespacho) || [],
    cargaPuntosShellBox: cargaPuntoShellBox,
  };

  if (facturaJson.cabecera.IdTipoDocumento === null) {
    delete facturaJson.cabecera.IdTipoDocumento;
  }

  console.log(facturaJson);
  return facturaJson;
}
