import React, { useState } from "react";
import { Box, TextField, IconButton, CircularProgress, Alert, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuSection from "../components/MenuSection";
import { useTheme } from "@mui/material/styles";
import ConsultarSaldoShellBox from "../api/consultarSaldoShellBox";

const ConsultarPuntos = () => {
  const [dni, setDni] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [points, setPoints] = useState(null);
  const theme = useTheme();

  const handleSearch = async cuit => {
    if (cuit.length !== 8) {
      setAlertMessage("Por favor, ingrese un CUIT válido de 11 dígitos.");
      return;
    }

    setIsLoading(true);
    setAlertMessage(null);

    try {
      const response = await ConsultarSaldoShellBox.getPuntos(dni);
      setPoints(response);
    } catch (error) {
      setAlertMessage("No se pudo obtener la información. Por favor, intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: "80%",
          maxWidth: "600px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          boxShadow: 3,
        }}
      >
        <Typography variant="h6" sx={{ mb: 4, textAlign: "center", fontWeight: "bold" }}>
          Consultar Puntos
        </Typography>

        <TextField
          label="DNI"
          value={dni}
          type="number"
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          onChange={e => setDni(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleSearch(dni)}>
                <SearchIcon sx={{ height: "2rem", width: "2rem" }} />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: "0.85rem", color: "gray" },
          }}
        />

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress sx={{ color: theme.bandera?.colorSecundario || "primary" }} />
          </Box>
        ) : points !== null ? (
          <Typography variant="h6" sx={{ mt: 2 }}>
            {points}
          </Typography>
        ) : null}

        {alertMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {alertMessage}
          </Alert>
        )}
      </Box>
    </>
  );
};

export default ConsultarPuntos;
